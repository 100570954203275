<template>
  <v-card>
    <v-card-title>
      <v-container fluid class="pa-0">
        <v-form ref="form">
          <v-row>
            <v-col>
              Aggregated ad feeds
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-autocomplete v-model="selectedCampaigns" :items="campaigns" multiple label="Campaigns" @change="onChangeMulti('selectedCampaigns')"
                required :rules="[v => !!(v && v.length) || 'Campaign is required']" prepend-icon="campaign">
              </v-autocomplete>
            </v-col>

            <v-col>
              <v-autocomplete v-model="selectedTopics" :items="topics" multiple label="Topics" @change="onChangeMulti('selectedTopics')"
                required :rules="[v => !!(v && v.length) || 'Topic is required']" prepend-icon="topic">
              </v-autocomplete>
            </v-col>

            <v-col>
              <v-autocomplete v-model="selectedGenders" :items="genders" multiple label="Genders" @change="onChangeMulti('selectedGenders')"
                required :rules="[v => !!(v && v.length) || 'Gender is required']" prepend-icon="accessibility">
              </v-autocomplete>
            </v-col>

            <v-col>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-autocomplete v-model="selectedAgeGroups" :items="ageGroups" multiple label="Age groups" @change="onChangeMulti('selectedAgeGroups')"
                required :rules="[v => !!(v && v.length) || 'Age group is required']" prepend-icon="hourglass_empty">
              </v-autocomplete>
            </v-col>

            <v-col>
              <v-autocomplete v-model="selectedMatchTypes" :items="matchTypes" multiple label="Match types" @change="onChangeMulti('selectedMatchTypes')"
                required :rules="[v => !!(v && v.length) || 'Match type is required']" prepend-icon="content_copy">
              </v-autocomplete>
            </v-col>

            <v-col>
              <!--
              <v-text-field v-model="selectedKeyword" label="Keyword" clearable prepend-icon="text_snippet">
              </v-text-field>
              -->
            </v-col>

            <v-col>
              <v-btn class="mt-2" color="primary" @click="aggregate">Aggregate</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-container>

    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="options"
      :footer-props="{
        'items-per-page-options': datatables_rowsPerPageItems,
      }"
      :server-items-length="totalItems"
      class="elevation-1"
    >
      <template v-slot:loading>
        Loading items..
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon :to="{ name: 'Aggregated ad feed', params: { aggFeedItemID: item.id }}">
          <v-icon small>edit</v-icon>
        </v-btn>
      </template>

      <template v-slot:[`footer.page-text`]="props">
        {{ datatables_footerPageText(totalItemsIsEstimate, estimatedItems, props) }}
      </template>

    </v-data-table>
  </v-card>
</template>

<script>
import dataTablesMixin from '@/mixins/datatables'
import auth from '@/auth'

export default {
  name: 'AggregatedFeedItemsTable',

  props: {
    pAccountID: { type: Number, required: true },
    pFeedID: { type: Number, required: true },
  },

  data () {
    return {
      headers: [
        { text: 'Campaign', value: 'campaign_name' },
        { text: 'Topic', value: 'topic' },
        { text: 'Gender', value: 'gender' },
        { text: 'Age group', value: 'age_group' },
        { text: 'Match type', value: 'match_type' },
        { text: 'Headline 1', value: 'headline1' },
        { text: 'Description line 1', value: 'description_line1' },
        { text: 'Path 1', value: 'path1' },
        { text: '# feeds', value: 'feed_item_count' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      items: [],
      campaigns: [],
      selectedCampaigns: ['- Aggregate -'],
      topics: [],
      selectedTopics: ['- All -'],
      genders: [],
      selectedGenders: ['- Aggregate -'],
      ageGroups: [],
      selectedAgeGroups: ['- Aggregate -'],
      matchTypes: [],
      selectedMatchTypes: ['- Aggregate -'],
      //selectedKeyword: '',
      loading: false,
      totalItems: 0,
      estimatedItems: 0,
      totalItemsIsEstimate: false,
      options: {},
      lsOptions: 'aggregatedFeedItems_options',
      lsFilters: 'aggregatedFeedItems_filters',
      refreshedAtText: 'Awaiting feed selection'
    }
  },

  mixins: [dataTablesMixin],

  computed: {
    aggregationEndpoint () {
      return '/a/google/ad-customizer-feeds/' + this.pFeedID + '/generate-user-set'
    },

    feedDataEndpoint () {
      return '/a/google/ad-customizer-feeds/' + this.pFeedID + '/aggregated-feed-items'
    },

    feedFiltersEndpoint () {
      return '/a/google/ad-customizer-feeds/' + this.pFeedID + '/feed-item-filters?xper_page=5000'
    },
  },

  watch: {
    options: {
      handler () {
        this.fetchFeedData()
      },
      deep: true,
    },
    pAccountID: {
      handler () {
        this.fetchFeedData()
      }
    },
    pFeedID: {
      handler () {
        this.fetchFeedData()
        this.fetchFeedFilters()
      }
    },
  },

  methods: {
    aggregate () {
      // make sure all selects have a value
      if (!this.$refs.form.validate()) {
        return
      }
      this.loading = true

      // send aggregation POST req to generate user set and await response

      // define filter object and save in local storage
      let filterObj = {
        'selectedCampaigns': this.selectedCampaigns,
        'selectedTopics': this.selectedTopics,
        'selectedGenders': this.selectedGenders,
        'selectedAgeGroups': this.selectedAgeGroups,
        'selectedMatchTypes': this.selectedMatchTypes
      }
      localStorage.setItem(this.lsFilters, JSON.stringify(filterObj))

      // pass filters to API as aggregation params
      var body = JSON.stringify(filterObj)
      this.$http.post(this.aggregationEndpoint, body).then(resp => {
        // select newly aggregated data
        this.fetchFeedData()
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'POST', url: this.aggregationEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loading = false)
    },

    fetchFeedData: function () {
      if (!this.pAccountID || !this.pFeedID) {
        this.items = []
        return
      }

      this.loading = true
      var myURL = this.feedDataEndpoint + '?xfields='

      // get fields from headers
      myURL = this.datatables_addHeaderFieldsToURI(myURL, this.headers, ['actions'], ['id'])

      // get paging and sorting from options via data table component and save to localStorage
      myURL = this.datatables_processURIOptions(myURL, this.options)
      localStorage.setItem(this.lsOptions, JSON.stringify(this.options))

      // only get feed for this user
      myURL += '&user_id=' + auth.user.id

      this.$http.get(myURL).then(resp => {
        if (resp.data.data) {
          this.items = resp.data.data
          this.totalItemsIsEstimate = resp.headers['x-total-count-estimated'] === 'true' ? true : false
          var itemCounts = this.datatables_getItemCounts(this.totalItemsIsEstimate, Number(resp.headers['x-total-count']))
          this.totalItems = itemCounts.totalItems
          this.estimatedItems = itemCounts.estimatedItems
        } else {
          this.items = []
        }
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loading = false)
    },

    fetchFeedFilters: function () {
      if (!this.pFeedID) {
        this.campaigns = []
        this.topics = []
        this.genders = []
        this.ageGroups = []
        this.matchTypes = []
        return
      }

      this.loading = true
      var myURL = this.feedFiltersEndpoint

      this.$http.get(myURL).then(resp => {
        this.campaigns = ['- Aggregate -', '- All -']
        this.topics = ['- All -']
        this.genders = ['- Aggregate -', '- All -']
        this.ageGroups = ['- Aggregate -', '- All -']
        this.matchTypes = ['- Aggregate -', '- All -']
        if (resp.data.data) {
          // filter the response for records with filter_type = "campaign name", select only the "filter_option" attribute of those records, 
          // and append those options to the campaigns array
          this.campaigns = this.campaigns.concat(resp.data.data.filter(el => el.filter_type === 'campaign_name').map(val => val.filter_option))

          // same for other filter options
          this.topics = this.topics.concat(resp.data.data.filter(el => el.filter_type === 'topic').map(val => val.filter_option))
          this.genders = this.genders.concat(resp.data.data.filter(el => el.filter_type === 'gender').map(val => val.filter_option))
          this.ageGroups = this.ageGroups.concat(resp.data.data.filter(el => el.filter_type === 'age_group').map(val => val.filter_option))
          this.matchTypes = this.matchTypes.concat(resp.data.data.filter(el => el.filter_type === 'match_type').map(val => val.filter_option))
        }
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loading = false)
    },

    // ensure that Aggregate and All are excluded from multiselect functionality using a reference to the supplied array name
    onChangeMulti: function (strArr) {
      if (this[strArr][this[strArr].length -1] === '- Aggregate -') { this[strArr] = ['- Aggregate -']; return }
      if (this[strArr][this[strArr].length -1] === '- All -') { this[strArr] = ['- All -']; return }
      this[strArr] = this[strArr].filter(el => el !== '- Aggregate -' && el !== '- All -')
    },
  },

  created: function () {
    // get feed data and refresh regularly to prevent stale data from being shown
    this.timer = setInterval(this.fetchFeedData, this.datatables_refreshIntervalMs)

    // get feed filter data
    this.fetchFeedFilters()

    // if present, use local sorting/paging options
    if (localStorage.getItem(this.lsOptions)) {
      this.options = JSON.parse(localStorage.getItem(this.lsOptions))
      // setting multisort here: in v-data-table didn't work
      this.options['multiSort'] = true
    } else {
      this.options = {'multiSort': true}
    }

    // if present, use local filters
    if (localStorage.getItem(this.lsFilters)) {
      let filters = JSON.parse(localStorage.getItem(this.lsFilters))
      if (filters['selectedCampaigns']) { this.selectedCampaigns = filters['selectedCampaigns'] }
      if (filters['selectedTopics']) { this.selectedTopics = filters['selectedTopics'] }
      if (filters['selectedGenders']) { this.selectedGenders = filters['selectedGenders'] }
      if (filters['selectedAgeGroups']) { this.selectedAgeGroups = filters['selectedAgeGroups'] }
      if (filters['selectedMatchTypes']) { this.selectedMatchTypes = filters['selectedMatchTypes'] }
    }
  },

  beforeDestroy () {
    clearInterval(this.timer)
  },
}
</script>
